import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Stack,
  Theme,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { ReportTile } from './report-tile'
import { useUpdateFolder } from '../../../hooks/mutations/useUpdateFolder'
import { useCreateFolder } from '../../../hooks/mutations/useCreateFolder'
import { Folder } from '../../../models'
import { useAddItemsToFavorites } from '../../../hooks/mutations/useAddItemToFavoritesFolder'
import { useRemoveItemFromFavorites } from '../../../hooks/mutations/useRemoveItemFromFavoritesFolder'

const useStyles = makeStyles((theme: Theme) => ({
  noReports: {
    marginTop: theme.spacing(10),
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
}))

export function ReportTiles(props: ReportTileProps) {
  const {
    isLoading,
    cards,
    title,
    folders,
    subtitle,
    isFavoritesLoading,
    favoritesFolder,
  } = props
  const classes = useStyles()

  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: createFolder } = useCreateFolder()
  const { mutateAsync: addToFavorites } = useAddItemsToFavorites()
  const { mutateAsync: removeFromFavorites } = useRemoveItemFromFavorites()

  const handleAddToFolder = async (
    folderId: string,
    report: any,
    newFolderName?: string
  ) => {
    if (!report.id) {
      return
    }

    if (newFolderName && folderId === '') {
      await createFolder({
        folderName: newFolderName,
        items: [
          { id: report.id, type: report.type || 'report', name: report.name },
        ],
      })
      return
    }

    const folder = folders.find(folder => folder.id === folderId)
    if (!folder) return
    await updateFolder({
      folderId: folder.id,
      items: [
        ...folder.items,
        { id: report.id, type: report.type || 'report', name: report.name },
      ],
    })
  }

  const handleFavorite = async (
    isFavorite: boolean,
    report: any //TODO: Add type
  ) => {
    if (isFavorite) {
      await removeFromFavorites({
        itemId: report.id,
      })
      return
    }

    await addToFavorites({
      item: {
        id: report.id,
        type: report.type || 'report',
        name: report.name,
      },
    })
  }

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    )
  }

  if (!cards || !cards.length) {
    return (
      <Stack
        sx={{
          width: '100%',
          maxWidth: '1764px',
          mt: 5.5,
        }}
      >
        {title && title !== '' && (
          <Stack direction='row' alignItems='baseline' gap={1} sx={{ mb: 2 }}>
            <Typography variant='h5' sx={{ fontWeight: 600, display: 'block' }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography sx={{ fontWeight: 400 }}>({subtitle})</Typography>
            )}
          </Stack>
        )}
      </Stack>
    )
  }

  return (
    <Stack
      sx={{
        width: '100%',
        maxWidth: '1764px',
        mt: 5.5,
      }}
    >
      {title && title !== '' && (
        <Stack direction='row' alignItems='baseline' gap={1} sx={{ mb: 2 }}>
          <Typography variant='h5' sx={{ fontWeight: 600, display: 'block' }}>
            {title}
          </Typography>
          {subtitle && (
            <Typography sx={{ fontWeight: 400 }}>({subtitle})</Typography>
          )}
        </Stack>
      )}

      <Grid spacing={6} alignContent={'center'} columns={6} container>
        {cards.map(card => {
          return (
            <Grid
              item
              xs={6}
              md={3}
              lg={2}
              key={`${title}-${card.id + card.name} - grid`}
            >
              <ReportTile
                key={`${title}-${card.id + card.name}`}
                title={card.name}
                isFavoritesLoading={isFavoritesLoading}
                description={card.description}
                id={card.id}
                type={card?.type || 'report'} //fixme: type is hardcoded
                imageUrl={card?.imageUrl}
                isFavorite={
                  favoritesFolder
                    ? favoritesFolder?.items?.some(
                        (item: any) => item.id === card.id
                      )
                    : folders
                        ?.find(folder => folder.isFavorites)
                        ?.items?.some((item: any) => item.id === card.id)
                }
                handleFavoritesClicked={() => {
                  handleFavorite(
                    favoritesFolder
                      ? favoritesFolder?.items?.some(
                          (item: any) => item.id === card.id
                        )
                      : folders
                          ?.find(folder => folder.isFavorites)
                          ?.items?.some((item: any) => item.id === card.id),
                    card
                  )
                }}
                folders={folders?.filter(
                  folder => !folder?.items.some(item => item.id === card.id)
                )}
                handleAddToFolder={(
                  folderId: string,
                  newFolderName?: string
                ) => {
                  handleAddToFolder(folderId, card, newFolderName)
                }}
              />
            </Grid>
          )
        })}
      </Grid>
    </Stack>
  )
}

type ReportTilesSkeletonProps = {
  numReports: number
  title: string
  subtitle?: string
}
export function ReportTilesSkeleton(props: ReportTilesSkeletonProps) {
  const { numReports, title, subtitle } = props

  return (
    <>
      <Stack
        sx={{
          width: '100%',
          maxWidth: '1764px',
          mt: 5.5,
        }}
      >
        {title && title !== '' && (
          <Stack direction='row' alignItems='baseline' gap={1} sx={{ mb: 2 }}>
            <Typography variant='h5' sx={{ fontWeight: 600, display: 'block' }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography sx={{ fontWeight: 400 }}>({subtitle})</Typography>
            )}
          </Stack>
        )}
        <Grid spacing={6} alignContent={'center'} columns={6} container>
          {Array.from(Array(numReports).keys()).map((_, index) => {
            return (
              <Grid item xs={6} md={3} lg={2} key={index}>
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: '#f5f3f3',
                    position: 'relative',
                    borderRadius: '8px',
                    padding: theme => theme.spacing(2),
                    alignItems: 'flex-start',
                    height: '100%',
                    paddingRight: theme => theme.spacing(1),
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  <Stack
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    gap={2}
                  >
                    <Skeleton
                      variant='circular'
                      sx={{
                        borderRadius: '50%',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '80px !important',
                        height: '80px !important',
                      }}
                    />
                    <Stack direction='column' justifyContent='center'>
                      <Stack
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        sx={{ width: '100%' }}
                      >
                        <Skeleton width='200px' height='32px' />
                      </Stack>
                      <Box>
                        <Skeleton width='180px' height='20px' />
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            )
          })}
        </Grid>
      </Stack>
    </>
  )
}

type ReportTileProps = {
  isLoading: boolean
  title: string
  cards: any[]
  subtitle?: string
  folders: Folder[]
  isFavoritesLoading: boolean
  favoritesFolder?: Folder
}
