import cs from 'classnames'
import { createStyles, makeStyles } from '@mui/styles'
import {
  AccountCircleOutlined,
  KeyboardBackspace,
  LogoutOutlined,
  Menu as MenuIcon,
} from '@mui/icons-material'
import {
  AppBar,
  Toolbar,
  IconButton,
  Theme,
  Tooltip,
  Stack,
  Box,
  Typography,
  MenuItem,
  Menu,
} from '@mui/material'
import CompanyMenu from './company-menu'
import { NotificationAlert } from './notification-alert'
import { useGetTheme } from '../../hooks'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom' // Import useLocation
import { bookmarkApi } from '../../api-interface'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { licenseAtom } from '../../state'
import { bookmarkAtom } from '../../state/bookmarkAtom'
import { getClientPrincipal } from '../auth/util'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      toolbarShrink: {
        [theme.breakpoints.up('md')]: {
          height: '64px',
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        [theme.breakpoints.down('md')]: {
          height: '40px !important',
          paddingLeft: `${theme.spacing(2)} !important`,
          paddingRight: `${theme.spacing(2)} !important`,
        },
      },

      shrink: {
        [theme.breakpoints.up('md')]: {
          height: '64px',
        },
        [theme.breakpoints.down('md')]: {
          height: '40px',
        },
      },
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 1301,
        borderBottom: '1px solid #e0e0e0',
      },
      toolbar: {
        minHeight: '40px',
        width: '100%',
        height: '64px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      buttonShrink: {
        height: '40px',
        width: '40px',
      },

      menuButton: {
        marginRight: theme.spacing(2),
        '&:hover, &.Mui-focusVisible': { color: theme.palette.secondary.dark },
      },
      bookmarkButton: {
        marginLeft: theme.spacing(2),
        '&:hover, &.Mui-focusVisible': { color: theme.palette.secondary.dark },
      },
      leftSide: {
        display: 'flex',
        alignItems: 'center',
      },
      rightSide: {
        display: 'flex',
        alignItems: 'center',
      },
      logo: {
        marginLeft: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
      },
      titleShrink: {
        [theme.breakpoints.up('md')]: {
          fontSize: '1.25rem',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '1rem',
          marginLeft: theme.spacing(1),
        },
      },
    }),
  { name: 'main-app-bar' }
)

export function MainAppBar(props: MainAppBarProps) {
  const { toggleOpen, fullWidth, pageWidth, showBackButton } = props
  const classes = useStyles()
  const { data: themeWithLogo } = useGetTheme()

  const history = useHistory() // Import useHistory from react-router-dom
  const location = useLocation() // Get the location object from the useLocation hook
  const params = useParams<any>()
  const licenseId = useRecoilValue(licenseAtom)

  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)
  const [reportId, setReportId] = useState('')

  const onMenuClick = () => {
    setBookmarkState({ ...bookmarkState, panelOpen: false })
    toggleOpen && toggleOpen()
  }

  useEffect(() => {
    if (bookmarkState?.selectedBookmark?.id) {
      window.history.replaceState(
        {},
        '',
        `/reports/${reportId}/${bookmarkState.selectedBookmark.id}`
      )
    }
  }, [bookmarkState?.selectedBookmark?.id, reportId])

  useEffect(() => {
    const getAndSetBookmarkState = async () => {
      const bookmarkId = params?.bookmark
      if (!bookmarkId) return

      const reportBookmarks = await bookmarkApi.getBookmarks(
        licenseId,
        reportId
      )

      const allBookmarks = [
        ...reportBookmarks.licenseBookmarks,
        ...reportBookmarks.ownedBookmarks,
        ...reportBookmarks.sharedBookmarks,
      ]

      const selectedBookmark = allBookmarks.find(
        bookmark => bookmark.id === bookmarkId
      )

      if (!selectedBookmark) {
        window.history.replaceState({}, '', `/reports/${reportId}`)
        return
      }
    }
    if (!reportId) return
    getAndSetBookmarkState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, reportId])

  useEffect(() => {
    const reportId = params?.id || ''

    if (!reportId || reportId.length < 12) {
      setReportId('')
      return
    }
    setReportId(reportId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  let BackButton = !toggleOpen && (
    <IconButton
      className={classes.menuButton}
      component='a'
      color='inherit'
      aria-label='menu'
      onClick={() => history.push('/')}
      size='small'
      sx={{
        height: '20px',
        width: '20px',
      }}
    >
      <KeyboardBackspace />
    </IconButton>
  )

  let MenuButton = (
    <IconButton
      className={classes.menuButton}
      color='inherit'
      aria-label='menu'
      onClick={onMenuClick}
      size='large'
    >
      <MenuIcon />
    </IconButton>
  )

  let MenuLogo = (
    <img
      src={themeWithLogo?.logo}
      alt='logo'
      style={{ height: 'auto', maxWidth: '150px', maxHeight: '50px' }}
    />
  )

  return (
    <AppBar
      elevation={0}
      position='fixed'
      className={cs(classes.appBar, { [classes.shrink]: fullWidth })}
    >
      <Toolbar
        className={cs(classes.toolbar, { [classes.toolbarShrink]: fullWidth })}
        sx={{ maxWidth: fullWidth ? '100%' : `${pageWidth}px` }}
      >
        <Box className={classes.leftSide}>
          {toggleOpen && MenuButton}
          {showBackButton && BackButton}
          {themeWithLogo?.logo && MenuLogo}
        </Box>

        <div className={classes.rightSide}>
          <ToolbarButtons />
        </div>
      </Toolbar>
      <NotificationAlert />
    </AppBar>
  )
}

function ToolbarButtons(props: ToolbarButtonsProps) {
  const setCurrentLicense = useSetRecoilState(licenseAtom)
  const [userDetails, setuserDetails] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const logout = (): void => {
    setCurrentLicense(null)
    window.location.href = '/.auth/logout'
  }

  useEffect(() => {
    getClientPrincipal().then(clientPrincipal => {
      const email = clientPrincipal?.userDetails
      setuserDetails(email)
    })
  }, [])

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const isMenuOpen = Boolean(anchorEl)

  return (
    <Stack sx={{ ml: 2 }} direction='row' gap={0}>
      <CompanyMenu />
      <Tooltip title={userDetails}>
        <Box>
          <IconButton
            onClick={handleMenuOpen}
            sx={{
              transition: 'all 0.3s ease-in-out',
              color: 'inherit',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <AccountCircleOutlined />
            </Box>
          </IconButton>
        </Box>
      </Tooltip>

      {/* Menu for user details */}
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem disabled>
          <Typography variant='body2'>{userDetails}</Typography>
        </MenuItem>
        <MenuItem onClick={logout}>
          <LogoutOutlined fontSize='small' />
          <Typography sx={{ ml: 1 }}>Log Out</Typography>
        </MenuItem>
      </Menu>
    </Stack>
  )
}

export type MainAppBarProps = {
  toggleOpen?: () => void
  title: string
  fullWidth?: boolean
  pageWidth?: number
  showBackButton?: boolean
}

export type ToolbarButtonsProps = {}
