import {
  TextField,
  Theme,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
)

/**
 * Can be used as a text field, or a select field by passing
 * the `selectOptions` prop
 */
export function FormField(props: FormFieldProps) {
  const {
    selectOptions,
    label,
    value,
    helperText,
    onTextChange,
    errorMessage,
    allowEmpty,
    onBlur,
    id,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <FormControl
      variant='filled'
      sx={{ width: '100%' }}
      fullWidth
      error={!!errorMessage}
    >
      {selectOptions ? (
        <>
          <InputLabel
            variant='filled'
            id={id}
            key={id}
            sx={{
              mt: 1,
            }}
          >
            {label}
          </InputLabel>
          <Select
            {...rest}
            color={errorMessage ? 'error' : 'secondary'}
            // label={label}
            className={classes.textField}
            variant='filled'
            fullWidth
            value={value}
            onBlur={onBlur || (() => {})}
            onChange={e => {
              if (allowEmpty && e.target.value === '(None)') {
                onTextChange('')
                return
              }
              onTextChange(e.target.value)
            }}
            id={id}
          >
            {renderMenuItems(selectOptions, allowEmpty)}
          </Select>
        </>
      ) : (
        <TextField
          {...rest}
          color={errorMessage ? 'error' : 'secondary'}
          className={classes.textField}
          variant='filled'
          label={label}
          fullWidth
          value={value}
          onBlur={onBlur || (() => {})}
          helperText={helperText}
          onChange={e => onTextChange(e.target.value)}
          id={id}
        />
      )}
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

export type FormFieldProps = {
  id?: string
  selectOptions?: string[] | { [value: string]: string }
  label: string
  value: string
  helperText?: string
  disabled?: boolean
  defaultValue?: string
  type?: string
  errorMessage?: string
  onTextChange?: (val: string) => void
  onBlur?: () => void
  allowEmpty?: boolean
}

/**
 * Accepts either an array of strings to use as select options
 * or an object whose keys are the select values, and the key-values
 * are the strings to display in the UI for each option
 */
function renderMenuItems(
  options: FormFieldProps['selectOptions'],
  allowEmpty?: boolean
) {
  if (!options) return null

  let optionsToReturn

  if (Array.isArray(options)) {
    optionsToReturn = options.map((item, i) => (
      <MenuItem key={`${item}-${i}`} value={item}>
        {item}
      </MenuItem>
    ))
  } else {
    optionsToReturn = Object.entries(options).map(([value, displayText], i) => (
      <MenuItem key={`${value}-${i}`} value={value}>
        {displayText}
      </MenuItem>
    ))
  }

  if (allowEmpty) {
    optionsToReturn.unshift(
      <MenuItem key='(None)' value='(None)'>
        (None)
      </MenuItem>
    )
  }

  return optionsToReturn
}
